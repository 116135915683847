import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { SideBySide } from '../components/side-by-side';
import { ButtonLink } from '../components/ui/button-link';

function AboutUsPage(): React.ReactElement {
  return (
    <>
      <SEO title="About Us Page" />
      <Layout>
        <Hero
          image={
            <StaticImage
              src="../images/about-hero.jpg"
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <h1 className="text-6xl">
              <span className="font-semibold">
                Quality Early <br />
                Childhood Education <br />
              </span>
              in Lake Cathie
            </h1>
          }
          cta={
            <ButtonLink variant="teal" to="#contact">
              Enquire Today
            </ButtonLink>
          }
        />
        <OurPromise />
        <AboutUs />
        <ContactSection />
      </Layout>
    </>
  );
}

function OurPromise(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            Our <span className="font-semibold">Promise</span>
          </h2>
          <div className="mt-5 prose">
            <p>
              Here at Cowarra Park Preschool and Long Day Care, we promise to
              offer children and families in the local area high-quality
              education and care - a place where children feel safe, valued,
              respected and where families feel a sense of belonging in their
              child's care environment.
            </p>
            <p>
              We aim to provide a stimulating environment based on interests
              that enrich abilities and development. Children at Cowarra Park
              Preschool are able to explore, question, make choices and grow
              through their experiences and interactions with both children and
              educators.
            </p>
            <p>
              As we work in partnerships with parents, staff and children, we
              strive to create an environment that encourages children to dream
              with a love for learning.
            </p>
            <p>
              In addition, our staff provide the highest quality of education
              and care through their qualification, dedication and experience.
            </p>
          </div>
        </SideBySide.ThreeCols>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/about-one.jpg"
            alt=""
            className="w-[17rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
      </SideBySide>
    </div>
  );
}

function AboutUs(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/about-two.jpg"
            alt=""
            className="w-[17rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            <span className="font-semibold">About Us</span>
          </h2>
          <div className="mt-5 prose">
            <p>
              Cowarra Park Preschool and Long Day Care is a privately owned and
              operated service. Established in 2000, the centre has been purpose
              built on a beautiful one-acre piece of land situated in the
              seaside town of Lake Cathie on the Mid North Coast of New South
              Wales.
            </p>
            <p>
              The centre is open 50 weeks a year and is licensed for 75 Children
              per day. Childcare Subsidy (CCS) is available to eligible families
              through the Centrelink.
            </p>
          </div>
          <p className="mt-12 underline text-teal">
            <a href="https://cdn.sanity.io/files/p7tep0zg/production/1c72de4603eb73c0ef8f22d8d8b0136004980be9.pdf?dl">
              Statement of Philosophy
            </a>
          </p>
        </SideBySide.ThreeCols>
      </SideBySide>
    </div>
  );
}

export default AboutUsPage;
